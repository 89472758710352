import React from 'react';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {motion} from 'framer-motion';
import {SectionHeader} from '../components/Headings';
import {PageHeader} from '../components/Headings';
import StaffTile from '../components/StaffTile';
import QuoteBlock from '../components/QuoteBlock';
import Layout from '../components/Layout';
import {pageVariants, pageTransition} from '../components/Transitions';

const About = ({data}) => {
  const {
    staff,
    title,
    description,
    intro,
    carousel: [headerImage],
    blockquote,
    ourStoryHeading,
    ourStoryBody,
    seomatic,
  } = data.craftAboutAboutEntry;
  return (
    <Layout hasContact seomatic={seomatic}>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className=" font-display">
        <div className="background relative">
          <PageHeader
            heading={title}
            subheading={description}
            content={
              headerImage && (
                <GatsbyImage
                  className="max-w-screen-xl m-auto"
                  style={{maxHeight: '600px'}}
                  imgStyle={{objectFit: 'cover'}}
                  image={getImage(headerImage.localFile)}
                  alt={headerImage.alt ?? title}
                />
              )
            }
          />
        </div>
        <div className="bg-white pl-6 pr-6">
          <div className="m-auto pt-8 md:pt-20 pb-16 max-w-5xl leading-8 lg:leading-9 text-black">
            <div
              className="md:text-center prose-lg article-body md:prose-xl"
              dangerouslySetInnerHTML={{__html: intro}}
            />
          </div>
          <div className="max-w-screen-xl m-auto w-full pb-8 grid">
            {blockquote.map(({id, quote, caption}, i) => (
              <QuoteBlock
                key={id}
                quote={quote}
                byline={caption}
                align={i % 2 ? 'right' : 'left'}
              />
            ))}
          </div>
          <a name="team">
            <div className="max-w-screen-xl m-auto w-full pb-8 grid grid-cols-2 md:grid-cols-3 gap-6 md:gap-16 lg:pb-24">
              {staff.map(({id, fullName, role, avatar: [avatar]}) => (
                <StaffTile
                  key={id}
                  image={avatar}
                  name={fullName}
                  title={role}
                />
              ))}
            </div>
          </a>
        </div>
        <div className="video-block pl-6 pr-6 lg:pl-6 lg:pr-6 lg:pt-8 pb-8 text-white">
          <div className="max-w-screen-xl m-auto w-full">
            <div className="m-auto pt-8 md:pt-12 pb-12 max-w-5xl leading-8 lg:leading-9">
              <SectionHeader light heading={ourStoryHeading} />
              <div
                className="md:text-center prose-md md:prose-xl"
                dangerouslySetInnerHTML={{__html: ourStoryBody}}
              />
            </div>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    craftAboutAboutEntry {
      title
      description
      intro
      ourStoryHeading
      ourStoryBody
      carousel {
        id
        ... on Craft_assets_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, layout: CONSTRAINED, quality: 90)
            }
          }
        }
      }
      staff {
        ... on Craft_User {
          id
          fullName
          role
          avatar {
            ... on Craft_assets_Asset {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 450, height: 675, quality: 90)
                }
              }
            }
          }
        }
      }
      blockquote {
        ... on Craft_blockquote_quote_BlockType {
          id
          quote
          caption
        }
      }
      seomatic {
        ...SeomaticFields
      }
    }
  }
`;

export default About;
