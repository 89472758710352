import React from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

const StaffTile = ({image, name, title}) => {
  return (
    <div className="text-black mb-8 lg:mb-0">
      <div className="">
        <GatsbyImage image={getImage(image?.localFile)} alt={name} />
        <h5 className="mt-3 font-bold">{name}</h5>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default StaffTile;
